/* eslint-disable sonarjs/cognitive-complexity */
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { styled, Theme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import WarningOutlined from '@mui/icons-material/WarningOutlined';
import { Banner } from '../../common/alerts/Banner';
import { Button } from '../../common/buttons/Button';
import { Editor } from './editor/Editor';
import { LegacyPackageDialog } from '../../workspace-dialogs/LegacyPackageDialog';
import { Listener } from './editor/Listener';
import { ReplayInvocationDialog } from '../../workspace-dialogs/ReplayInvocationDialog';
import { SetModel } from './editor/SetModel';
import { Shortcut, ScriptHelperPopup } from './ScriptHelperPopup';
import { WorkspaceEnvironments } from '../../../data/workspace';

export interface Position {
    lineNumber: number;
    column: number;
}

export interface ScriptPosition {
    key: string;
    position: Position;
}

export interface ScriptDetailsProps {
    content: string;
    editorAlertMessage?: string;
    editorReadOnly: boolean;
    environmentUid?: string;
    executing: boolean;
    hasUnsavedChanges: boolean;
    impersonating: boolean;
    isMondayUser: boolean;
    legacyImports: string[] | null;
    legacyPackagesDialogErrors?: string;
    legacyPackagesDialogLoading?: boolean;
    legacyPackagesDialogOpen: boolean;
    readOnlyTemplateMode: boolean;
    replayInvocationDialogOpen: boolean;
    replayInvocationPayload: string;
    replayInvocationPayloadLoading: boolean;
    saving: boolean;
    scriptName: string;
    scriptStoredPosition?: Position;
    scriptUid: string;
    selectedEnvironment?: WorkspaceEnvironments[number];
    scriptHelperPopupOpen?: boolean;
    scriptHelperPopupVisible?: boolean;
    workspaceLocked: boolean;
    onAssumeWorkspaceEditControlForReplayInvocation(): void;
    onClickMondayCodeExamplesUrl(): void;
    onCloseReplayInvocationDialog(): void;
    onContentChange(content: string): void;
    onCopyImports(imports: string): void;
    onEditorDisableReadOnly(): void;
    onImportApiHandler(): void;
    onLegacyPackagesDialogOpen(isOpen: boolean): void;
    onPackageNameReplacement(content: string): void;
    onReplayInvocationTriggered(payload: string): void;
    onSave(): void;
    onScriptNameChange(scriptName: string): void;
    onScriptHelperPopupClose(): void;
    onScriptHelperPopupOpen(): void;
    onStoreScriptPosition?(scriptPosition: ScriptPosition): void;
    onTriggerScript(uid: string): void;
}

export const toolbarHeight = 28;

const disabledButtonStyles = (theme: Theme) =>
    ({
        '&:disabled .MuiSvgIcon-root': {
            color: theme.palette.text.disabled,
        },
    } as const);

const StyledBox = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
        height: 16,
        marginRight: theme.spacing(1),
        width: 16,
    },
}));

const StyledContainer = styled('div')(() => ({
    height: '100%',
    overflow: 'hidden',
    width: '100%',
}));

const StyledCancelButton = styled(IconButton)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.disabled,
        height: 20,
        width: 20,
    },
}));

const StyledEditableArea = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginLeft: theme.spacing(-0.5),
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
    ...disabledButtonStyles(theme),
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        height: 22,
        width: 22,
    },
}));

const StyledEditorButton = styled(IconButton)(({ theme }) => ({
    ...disabledButtonStyles(theme),
    marginRight: theme.spacing(1.5),
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        height: 24,
        width: 24,
    },
}));

const StyledEditorIconContainer = styled('div')(({ theme }) => ({
    borderRadius: 0,
    display: 'flex',
    flexWrap: 'nowrap',
    marginLeft: theme.spacing(3),
}));

const StyledInputAdornment = styled(InputAdornment)(() => ({}));

const StyledLoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.text.disabled,
    height: '26px !important',
    position: 'absolute',
    width: '26px !important',
}));

const StyledLowerToolbar = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.action.selected,
    bottom: -32,
    height: toolbarHeight,
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 4.5),
    position: 'absolute',
    width: '100%',
}));

const StyledRoundedBox = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
    cursor: 'pointer',
    height: 24,
    justifyContent: 'center',
    padding: theme.spacing(0, 0.5),
    '&:hover': {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        '& .MuiTypography-root': {
            color: theme.palette.secondary.main,
        },
    },
}));

const StyledSaveIcon = styled(SaveOutlinedIcon)(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
}));

// const StyledReflexScriptDetails = styled(ReflexElement)({});

const StyledScriptName = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

const StyledScriptNameContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginRight: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        width: 360,
    },
}));

const StyledToolbar = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.constants.boxShadow,
    height: theme.spacing(6),
    justifyContent: 'flex-start',
    width: '100%',
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const platform = (navigator as any).userAgentData ? (navigator as any).userAgentData.platform : navigator.platform;
const shortcuts: Shortcut[] = [
    { combinations: ['CTRL + Space'], text: 'to open/hide auto-complete suggestions.' },
    {
        combinations: platform.toLowerCase().includes('mac') ? ['COMMAND + .'] : ['CTRL + .'],
        text: 'to show import suggestions.',
    },
    { combinations: ['F1', 'Fn + F1'], text: 'to view all commands.' },
];

const PLAY_TOOLTIP = 'Trigger Script Manually';
const SAVE_TOOLTIP = `Save script (${platform.toLowerCase().includes('mac') ? 'COMMAND + S' : 'CTRL + S'})`;
const ENABLE_EDIT_TOOLTIP = 'Enable name editing';
const EDIT_NAME_TOOLTIP = 'Edit name';
const CANCEL_TOOLTIP = 'Cancel name editing';
const IMPORT_API_HANDLER_TOOLTIP = 'Import API Connection';

export const ScriptDetails: React.FC<ScriptDetailsProps> = ({
    content,
    editorAlertMessage,
    editorReadOnly,
    environmentUid,
    executing,
    hasUnsavedChanges,
    impersonating,
    legacyImports,
    legacyPackagesDialogErrors,
    legacyPackagesDialogLoading,
    legacyPackagesDialogOpen,
    isMondayUser,
    readOnlyTemplateMode,
    replayInvocationDialogOpen,
    replayInvocationPayload,
    replayInvocationPayloadLoading,
    saving,
    scriptName,
    scriptUid,
    selectedEnvironment,
    scriptHelperPopupOpen,
    scriptHelperPopupVisible,
    scriptStoredPosition,
    workspaceLocked,
    onAssumeWorkspaceEditControlForReplayInvocation,
    onClickMondayCodeExamplesUrl,
    onCloseReplayInvocationDialog,
    onContentChange,
    onCopyImports,
    onEditorDisableReadOnly,
    onImportApiHandler,
    onLegacyPackagesDialogOpen,
    onPackageNameReplacement,
    onReplayInvocationTriggered,
    onSave,
    onScriptNameChange,
    onScriptHelperPopupClose,
    onScriptHelperPopupOpen,
    onStoreScriptPosition,
    onTriggerScript,
}) => {
    const [currentScriptName, setCurrentScriptName] = useState(scriptName);
    const [focused, setFocused] = useState(false);
    const [renameDialogShown, setRenameDialogShown] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const isContentNeedsPackageReplacement =
        legacyImports &&
        (!renameDialogShown || scriptName !== currentScriptName) &&
        !workspaceLocked &&
        !readOnlyTemplateMode &&
        !impersonating &&
        !selectedEnvironment?.deployment?.version;

    useEffect(() => {
        if (isContentNeedsPackageReplacement) {
            onLegacyPackagesDialogOpen(true);
        }
        setRenameDialogShown(true);

        setCurrentScriptName(scriptName);
        setFocused(false);
    }, [scriptName]);
    const handleContentChange = (content: string): void => {
        onContentChange(content);
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setCurrentScriptName(event.target.value);
    };

    const canApplyScriptNameChange = !!currentScriptName && currentScriptName !== scriptName;

    const handleScriptNameApply = (): void => {
        onScriptNameChange(currentScriptName);
        setFocused(false);
        setCurrentScriptName(scriptName);
    };

    const handleCancel = (): void => {
        setCurrentScriptName(scriptName);
        setFocused(false);
    };

    const handleScriptHelperPopup = (): void => {
        if (scriptHelperPopupOpen) {
            onScriptHelperPopupClose();
        } else {
            onScriptHelperPopupOpen();
        }
    };

    const handlePackageNameReplacement = (): void => {
        onPackageNameReplacement(content);
    };

    const handleReplayInvocation = (payload: string): void => {
        onReplayInvocationTriggered(payload);
    };
    const environmentDeployed = selectedEnvironment?.deployment !== undefined;

    const icons = (
        <StyledEditorIconContainer>
            <Tooltip title={PLAY_TOOLTIP}>
                <span>
                    <StyledEditorButton
                        disabled={executing || workspaceLocked}
                        onClick={() => onTriggerScript(scriptUid)}
                        sx={() => {
                            return {
                                '& .MuiSvgIcon-root': {
                                    color: executing ? 'text.disabled' : undefined,
                                },
                            };
                        }}
                    >
                        <PlayCircleOutlineRoundedIcon />
                        {executing && <StyledLoadingSpinner />}
                    </StyledEditorButton>
                </span>
            </Tooltip>
            <Tooltip title={SAVE_TOOLTIP}>
                <span>
                    <StyledEditorButton
                        onClick={onSave}
                        disabled={saving || !hasUnsavedChanges || workspaceLocked}
                        sx={(theme) => {
                            return {
                                '& .MuiSvgIcon-root': {
                                    border:
                                        !saving && hasUnsavedChanges
                                            ? `1px solid ${theme.palette.primary.dark}`
                                            : 'none',
                                },
                            };
                        }}
                    >
                        <StyledSaveIcon />
                        {saving && <StyledLoadingSpinner />}
                    </StyledEditorButton>
                </span>
            </Tooltip>
            <Tooltip title={IMPORT_API_HANDLER_TOOLTIP}>
                <span>
                    <StyledEditorButton
                        onClick={() => onImportApiHandler()}
                        disabled={editorReadOnly || workspaceLocked || environmentDeployed}
                        sx={() => {
                            return {
                                '& .MuiSvgIcon-root': {
                                    color: editorReadOnly ? 'text.disabled' : undefined,
                                },
                            };
                        }}
                    >
                        <DownloadOutlinedIcon />
                    </StyledEditorButton>
                </span>
            </Tooltip>
        </StyledEditorIconContainer>
    );

    const editableScriptName = focused ? (
        <StyledEditableArea>
            <StyledTextField
                InputProps={{
                    startAdornment: (
                        <StyledInputAdornment position="start">
                            <Tooltip
                                title={currentScriptName ? EDIT_NAME_TOOLTIP : ENABLE_EDIT_TOOLTIP}
                                placement="top"
                            >
                                <StyledEditButton
                                    onClick={() =>
                                        currentScriptName ? handleScriptNameApply() : inputRef.current?.focus()
                                    }
                                >
                                    <EditOutlinedIcon />
                                </StyledEditButton>
                            </Tooltip>
                        </StyledInputAdornment>
                    ),
                    endAdornment: (
                        <StyledInputAdornment position="end">
                            <Tooltip title={CANCEL_TOOLTIP} placement="top">
                                <StyledCancelButton onClick={() => handleCancel()}>
                                    <CancelIcon />
                                </StyledCancelButton>
                            </Tooltip>
                        </StyledInputAdornment>
                    ),
                }}
                inputRef={inputRef}
                size="small"
                variant="outlined"
                autoFocus
                placeholder="Enter new name"
                value={currentScriptName}
                onChange={handleNameChange}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && canApplyScriptNameChange) {
                        handleScriptNameApply();
                    } else if (event.key === 'Escape') {
                        handleCancel();
                    }
                }}
            ></StyledTextField>
            <Button size="small" disabled={!canApplyScriptNameChange} onClick={handleScriptNameApply}>
                Update
            </Button>
        </StyledEditableArea>
    ) : (
        <StyledScriptNameContainer>
            <Tooltip title={EDIT_NAME_TOOLTIP} placement="top">
                <StyledEditButton onClick={() => setFocused(true)}>
                    <EditOutlinedIcon />
                </StyledEditButton>
            </Tooltip>
            <StyledScriptName onClick={() => setFocused(true)}>{currentScriptName}</StyledScriptName>
        </StyledScriptNameContainer>
    );

    const readOnlyScriptName = (
        <StyledScriptNameContainer>
            <StyledScriptName sx={{ cursor: 'default' }}>{currentScriptName}</StyledScriptName>
        </StyledScriptNameContainer>
    );

    return (
        <StyledContainer data-test-id="script-details">
            <StyledToolbar>
                {!readOnlyTemplateMode && icons}
                {!readOnlyTemplateMode && !workspaceLocked && !environmentDeployed
                    ? editableScriptName
                    : readOnlyScriptName}
                {!readOnlyTemplateMode &&
                    !workspaceLocked &&
                    !impersonating &&
                    legacyImports?.length &&
                    !selectedEnvironment?.deployment?.version && (
                        <Button
                            onClick={() => onLegacyPackagesDialogOpen(true)}
                            color="warning"
                            size="small"
                            variant="outlined"
                            startIcon={<WarningOutlined color="warning" />}
                            sx={{
                                marginLeft: 'auto',
                                marginRight: (theme) => theme.spacing(2),
                            }}
                        >
                            Upgrade Legacy Packages
                        </Button>
                    )}
            </StyledToolbar>
            {!readOnlyTemplateMode && editorAlertMessage && (
                <>
                    {editorReadOnly ? (
                        <Banner
                            severity="info"
                            alertTitle={editorAlertMessage}
                            action={
                                <Button variant="contained" onClick={onEditorDisableReadOnly}>
                                    Disable Read-Only Mode
                                </Button>
                            }
                            text={`Editor won't be able to display correct feedback until types have been loaded. Disable read-only mode if you feel brave.`}
                        />
                    ) : (
                        <Banner
                            severity="info"
                            alertTitle={editorAlertMessage}
                            text={`Editor won't be able to display correct feedback until types have been loaded.`}
                        />
                    )}
                </>
            )}
            <Editor
                hasUnsavedChanges={hasUnsavedChanges}
                scriptUid={scriptUid}
                value={content}
                name={scriptName}
                uid={scriptUid}
                readOnly={editorReadOnly || readOnlyTemplateMode || workspaceLocked || environmentDeployed}
                onSave={onSave}
                storedPosition={scriptStoredPosition}
                onStoreScriptPosition={onStoreScriptPosition}
                environmentUid={environmentUid}
            >
                {scriptName && <SetModel scriptName={scriptName} />}
                <Listener onChange={handleContentChange} />
                <StyledLowerToolbar>
                    {scriptHelperPopupVisible && (
                        <StyledBox onClick={handleScriptHelperPopup}>
                            {isMondayUser ? (
                                <StyledRoundedBox>
                                    <InfoOutlinedIcon />
                                    <Typography>Help</Typography>
                                </StyledRoundedBox>
                            ) : (
                                <StyledRoundedBox>
                                    <KeyboardAltOutlinedIcon />
                                    <Typography>Shortcuts</Typography>
                                </StyledRoundedBox>
                            )}
                        </StyledBox>
                    )}
                </StyledLowerToolbar>
            </Editor>
            <ScriptHelperPopup
                onClickMondayCodeExamplesUrl={onClickMondayCodeExamplesUrl}
                onClose={onScriptHelperPopupClose}
                isMondayUser={isMondayUser}
                open={scriptHelperPopupOpen && scriptHelperPopupVisible}
                shortcuts={shortcuts}
            />
            <LegacyPackageDialog
                open={legacyPackagesDialogOpen}
                onDialogOpen={onLegacyPackagesDialogOpen}
                onPackageNameReplacement={handlePackageNameReplacement}
                legacyImports={legacyImports}
                onCopyImports={onCopyImports}
                saving={legacyPackagesDialogLoading}
                errors={legacyPackagesDialogErrors}
            />
            <ReplayInvocationDialog
                open={replayInvocationDialogOpen}
                onTrigger={handleReplayInvocation}
                content={replayInvocationPayload}
                onClose={onCloseReplayInvocationDialog}
                loading={replayInvocationPayloadLoading}
                workspaceLocked={workspaceLocked}
                onAssumeWorkspaceEditControl={onAssumeWorkspaceEditControlForReplayInvocation}
            />
        </StyledContainer>
    );
};
