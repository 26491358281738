import { useObservableState } from 'observable-hooks';
import {
    cancelOrganizationPlanSuccessStateDialogOpen$,
    cancelOrganizationPlanFeedbackDialogOpen$,
    cancelOrganizationPlanSuccessStatePlan$,
    closeCancelOrganizationPlanSuccessStateDialogAction$,
} from '../../../store/organization/cancelOrganizationPlan';
import { CancelOrganizationPlanSuccessStateDialog } from '../../../components/organizations/plan/cancel-organization-plan/CancelOrganizationPlanSuccessStateDialog';
import { loggedInUserDetails$ } from '../../../store/user';
import { mondayServiceManagementUrl, supportPortalUrl } from '../../../utils/documentation';
import { MONDAY_ORIGIN } from '../../../utils/constants';

export const CancelOrganizationPlanSuccessStateContainer: React.FC = () => {
    const plan = useObservableState(cancelOrganizationPlanSuccessStatePlan$);
    const open = useObservableState(cancelOrganizationPlanSuccessStateDialogOpen$);
    const { userOrigin } = useObservableState(loggedInUserDetails$) ?? {};

    if (!plan) {
        return null;
    }

    const handleContinue = (): void => {
        closeCancelOrganizationPlanSuccessStateDialogAction$.next();
        cancelOrganizationPlanFeedbackDialogOpen$.next(true);
    };

    const isMondayUser = userOrigin === MONDAY_ORIGIN;

    return (
        <CancelOrganizationPlanSuccessStateDialog
            open={open}
            plan={plan}
            endDate={plan.endDate}
            supportPortalUrl={isMondayUser ? mondayServiceManagementUrl : supportPortalUrl}
            onContinue={handleContinue}
        />
    );
};
