import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Navigate, Outlet } from 'react-location';
import useResizeObserver from 'use-resize-observer';
import { AppBanners } from './AppBanners';
import { Sidenav } from './side-navigation';
import { UserNameDetails } from '../../utils/types';
import { SidenavOrganization } from '@avst-stitch/repository-lib/lib/rpcs/getMyDetails';
import { getBasePath } from '../../utils/path';
import { ThemeMode } from '../../theme';

export interface AppMainProps {
    helpAndSupportOpen?: boolean;
    impersonating?: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
    isConnectedToFeedback?: boolean;
    isLogOutBusy?: boolean;
    notificationBanner?: {
        message: string;
        level: 'info' | 'warning' | 'error';
    };
    organizations?: SidenavOrganization[];
    rootPath: string;
    selectedOrganizationUid?: string;
    selectedPath: string;
    sessionExpired?: boolean;
    showDashboard?: boolean;
    showSessionExpiredWarning?: boolean;
    showUpgradePlan?: boolean;
    themeMode: ThemeMode;
    userCredentials: UserNameDetails;
    useRouter?: boolean;
    basePath: string;
    homePath: string;
    onSetLoadTokens(loadTokens: boolean): void;
    onStopImpersonation?(): void;
    onChangeThemeMode(mode: ThemeMode): void;
    onCreateNewOrganization(): void;
    onLogOut(): void;
    onManageAllOrganizations(): void;
    onOpenHelpAndSupport(): void;
    onSelectOrganization(uid: string): void;
}

const StyledWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'bannersHeight',
})<{ bannersHeight: number }>(({ theme, bannersHeight }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: `calc(100% - ${bannersHeight}px)`,
    position: 'relative',
}));

const StyledInnerContent = styled('main')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    overflowX: 'hidden',
    position: 'relative',
    width: '100%',
}));

export const AppMain: React.FC<AppMainProps> = ({
    helpAndSupportOpen,
    impersonating,
    isConnectedToFeedback,
    isLogOutBusy,
    notificationBanner,
    organizations,
    rootPath,
    selectedOrganizationUid,
    selectedPath,
    sessionExpired,
    showDashboard = true,
    showSessionExpiredWarning,
    showUpgradePlan,
    themeMode,
    userCredentials,
    useRouter = true,
    basePath,
    homePath,
    onChangeThemeMode,
    onCreateNewOrganization,
    onLogOut,
    onManageAllOrganizations,
    onOpenHelpAndSupport,
    onSelectOrganization,
    onSetLoadTokens,
    onStopImpersonation,
}) => {
    const [bannersHeight, setBannersHeight] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useResizeObserver({
        ref: containerRef,
        onResize: () => {
            if (containerRef.current) {
                setBannersHeight(containerRef.current.clientHeight);
            }
        },
    });

    return (
        <>
            <AppBanners
                isConnectedToFeedback={isConnectedToFeedback}
                impersonating={impersonating}
                onStopImpersonation={onStopImpersonation}
                notificationBanner={notificationBanner}
                showRefreshSessionWarning={showSessionExpiredWarning && sessionExpired}
                onSetLoadTokens={onSetLoadTokens}
            />
            <StyledWrapper bannersHeight={bannersHeight}>
                <CssBaseline />
                <Sidenav
                    helpAndSupportOpen={helpAndSupportOpen}
                    isLogOutBusy={isLogOutBusy}
                    organizations={organizations}
                    selectedOrganizationUid={selectedOrganizationUid}
                    selectedPath={selectedPath}
                    showUpgradePlan={showUpgradePlan}
                    themeMode={themeMode}
                    userCredentials={userCredentials}
                    useRouter={useRouter}
                    basePath={basePath}
                    homePath={homePath}
                    onChangeThemeMode={onChangeThemeMode}
                    onCreateNewOrganization={onCreateNewOrganization}
                    onLogOut={onLogOut}
                    onManageAllOrganizations={onManageAllOrganizations}
                    onOpenHelpAndSupport={onOpenHelpAndSupport}
                    onSelectOrganization={onSelectOrganization}
                />
                <StyledInnerContent>
                    {useRouter && (
                        <>
                            <Outlet />
                            {window.location.pathname === rootPath && (
                                <Navigate to={getBasePath() + (showDashboard ? 'dashboard' : 'templates')} />
                            )}
                        </>
                    )}
                </StyledInnerContent>
            </StyledWrapper>
        </>
    );
};
