import { BehaviorSubject, map, Subject } from 'rxjs';
import { monitor } from './monitor';
import { loadApps } from './apps';
import {
    getOnboardingTemplates,
    getScriptingFamiliarityOptions,
    OnboardingTemplates,
    saveOnboardingResponses,
    scriptingFamiliarityOptions,
} from '../data/onboarding';
import { OnboardingResponses } from '../components/onboarding/OnboardingWizard';
import { getIndustryRoles } from '../data/user';
import { industryRoles$ } from './roles';
import { segmentAndPendoAnalyticsTrack } from '../data/analytics';
import { loggedInUserDetails$ } from './user';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { InformativeError } from '../utils/repository';
import {
    organizationPlanSuccessStateContent$,
    organizationPlanSuccessStateDialogOpen$,
} from './organization/changeOrganizationPlan';
import { saveLocalStorage } from '../utils/localStorage';
import { isHashWebapp } from '../utils/path';
import { navigateAction$ } from './navigation';

export const onboardingWizardOpen$ = monitor('onboardingWizardOpen$', new BehaviorSubject(false));
export const onboardingWizardLoading$ = monitor('onboardingWizardLoading$', new BehaviorSubject(false));
export const onboardingWizardSaving$ = monitor('onboardingWizardSaving$', new BehaviorSubject(false));
export const scriptingFamiliarityOptions$ = monitor(
    'scriptingFamiliarityOptions$',
    new BehaviorSubject<scriptingFamiliarityOptions | undefined>(undefined)
);
export const openOnboardingWizardAction$ = monitor('openOnboardingWizardAction$', new Subject<void>());
export const closeOnboardingWizardAction$ = monitor('closeOnboardingWizardAction$', new Subject<void>());
export const saveOnboardingResponsesAction$ = monitor(
    'saveOnboardingResponsesAction$',
    new Subject<OnboardingResponses>()
);
const onboardingStartTime$ = monitor('onboardingStartTime$', new BehaviorSubject<Date>(new Date()));
export const profileUpdateDialogOpen$ = monitor('profileUpdateDialogOpen$', new BehaviorSubject(false));
export const startedFromProfileUpdate$ = monitor('startedProfileUpdate$', new BehaviorSubject(false));
export const openProfileUpdateDialogAction$ = monitor('openProfileUpdateDialogAction$', new Subject<void>());
export const skipProfileUpdateDialogAction$ = monitor('skipProfileUpdateDialogAction$', new Subject<void>());
export const updateProfileUpdateDialogAction$ = monitor('updateProfileUpdateDialogAction$', new Subject<void>());
export const profileUpdateSuccessDialogOpen$ = monitor('profileUpdateSuccessDialogOpen$', new BehaviorSubject(false));
export const closeProfileUpdateSuccessDialogAction$ = monitor(
    'closeProfileUpdateSuccessDialogAction$',
    new Subject<void>()
);
export const viewProfileDialogAction$ = monitor('viewProfileDialogAction$', new Subject<void>());

export const onboardingTemplateSelectorOpen$ = monitor('onboardingTemplateSelectorOpen$', new BehaviorSubject(false));
export const openOnboardingTemplateSelectorAction$ = monitor(
    'openOnboardingTemplateSelectorAction$',
    new Subject<void>()
);
export const closeOnboardingTemplateSelectorAction$ = monitor(
    'closeOnboardingTemplateSelectorAction$',
    new Subject<void>()
);
export const onboardingTemplateSelectorTemplates$ = monitor(
    'onboardingTemplateSelectorTemplates$',
    new BehaviorSubject<OnboardingTemplates | undefined>(undefined)
);

openOnboardingWizardAction$
    .pipe(
        map(async () => {
            onboardingWizardLoading$.next(true);
            onboardingWizardOpen$.next(true);
            const startedFromProfileUpdate = startedFromProfileUpdate$.value;
            try {
                const [scriptingFamiliarityOptions, roles] = await Promise.all([
                    getScriptingFamiliarityOptions(),
                    getIndustryRoles(),
                    loadApps(),
                ]);
                scriptingFamiliarityOptions$.next(scriptingFamiliarityOptions);
                industryRoles$.next(roles);
                if (!startedFromProfileUpdate) {
                    segmentAndPendoAnalyticsTrack('Onboarding Started', {
                        userOrigin: loggedInUserDetails$.value?.userOrigin,
                    });
                }
                onboardingWizardLoading$.next(false);
                onboardingStartTime$.next(new Date());
            } catch (e) {
                onboardingWizardLoading$.next(false);
                closeOnboardingWizardAction$.next();
                console.error(e);
            }
        })
    )
    .subscribe();

closeOnboardingWizardAction$.subscribe(() => {
    onboardingWizardOpen$.next(false);
});

saveOnboardingResponsesAction$
    .pipe(
        map(
            async ({
                personalization,
                selectedApps,
                selectedCustomRole,
                selectedRole,
                selectedScriptingFamiliarity,
                // eslint-disable-next-line sonarjs/cognitive-complexity
            }) => {
                onboardingWizardSaving$.next(true);
                const startedFromProfileUpdate = startedFromProfileUpdate$.value;
                try {
                    await saveOnboardingResponses({
                        personalization: personalization,
                        selectedApps: selectedApps,
                        roleUid: selectedRole,
                        customRole: selectedCustomRole,
                        scriptingFamiliarityUid: selectedScriptingFamiliarity,
                    });
                    const values = {
                        scriptingFamiliarityUid: selectedScriptingFamiliarity,
                        userAppPreference: selectedApps,
                        syncPreference: personalization.sync,
                        migrationPreference: personalization.migrations,
                        integrationsPreference: personalization.integrations,
                    };

                    if (selectedCustomRole) {
                        loggedInUserDetails$.next({
                            ...loggedInUserDetails$.value,
                            customRole: selectedCustomRole,
                            roleUid: null,
                            ...values,
                        });
                    } else {
                        loggedInUserDetails$.next({
                            ...loggedInUserDetails$.value,
                            customRole: null,
                            roleUid: selectedRole,
                            ...values,
                        });
                    }
                    if (!startedFromProfileUpdate) {
                        segmentAndPendoAnalyticsTrack('Onboarding Completed', {
                            userOrigin: loggedInUserDetails$.value?.userOrigin,
                            completionTime: (new Date().getTime() - onboardingStartTime$.value?.getTime()) / 1000,
                        });
                    }
                } catch (e) {
                    console.error('Failed to save onboarding responses', e);

                    let message: string;
                    if (e instanceof InformativeError) {
                        message = e.message;
                    } else {
                        message = 'Error saving onboarding responses';
                    }

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message,
                    });
                }
                onboardingWizardSaving$.next(false);
                onboardingWizardOpen$.next(false);
                if (!startedFromProfileUpdate) {
                    const pathNameParts = window.location.pathname.split('/');
                    const pathNameFirstPart = isHashWebapp() && pathNameParts[2] ? pathNameParts[2] : pathNameParts[1];
                    const pathNameSecondPart = isHashWebapp() && pathNameParts[3] ? pathNameParts[3] : pathNameParts[2];
                    if (pathNameFirstPart === 'template' && pathNameSecondPart) {
                        navigateAction$.next(`template/${pathNameSecondPart}`);
                    }
                    organizationPlanSuccessStateContent$.next({ state: 'onboarding', tier: 'free' });
                    organizationPlanSuccessStateDialogOpen$.next(true);
                } else {
                    localStorage.removeItem('hasSkippedProfileUpdate');
                    localStorage.removeItem('openProfileUpdate');
                    profileUpdateSuccessDialogOpen$.next(true);
                    startedFromProfileUpdate$.next(false);
                }
            }
        )
    )
    .subscribe();

openProfileUpdateDialogAction$.subscribe(() => profileUpdateDialogOpen$.next(true));

updateProfileUpdateDialogAction$.subscribe(() => {
    profileUpdateDialogOpen$.next(false);
    startedFromProfileUpdate$.next(true);
    openOnboardingWizardAction$.next();
});

skipProfileUpdateDialogAction$.subscribe(() => {
    saveLocalStorage('hasSkippedProfileUpdate', true);
    profileUpdateDialogOpen$.next(false);
});

closeProfileUpdateSuccessDialogAction$.subscribe(() => profileUpdateSuccessDialogOpen$.next(false));

openOnboardingTemplateSelectorAction$
    .pipe(
        map(async () => {
            try {
                const templates = await getOnboardingTemplates();

                if (templates.length > 0) {
                    onboardingTemplateSelectorTemplates$.next(templates);
                    onboardingTemplateSelectorOpen$.next(true);
                }
            } catch (e) {
                closeOnboardingTemplateSelectorAction$.next();
                console.error('Failed to get onboarding templates', e);
            }
        })
    )
    .subscribe();

closeOnboardingTemplateSelectorAction$.subscribe(() => {
    onboardingTemplateSelectorOpen$.next(false);
    onboardingTemplateSelectorTemplates$.next(undefined);
});
