import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AbsoluteCenteredLoadingSpinner } from '../../../common/loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../../common/buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { Alert } from '../../../common/alerts/Alert';
import { ReadOnlyTextField } from '../../../common/textfield/ReadOnlyTextField';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import Link from '@mui/material/Link';
import { useState } from 'react';

interface ZenDeskEventListenerSetupDialogProps {
    errors?: string;
    loading?: boolean;
    saving?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    zendeskUrl?: string;
    webhookUrlId: string;
    workspaceLocked?: boolean;
    onClose: () => void;
}

export const ZenDeskEventListenerSetupDialog: React.FC<ZenDeskEventListenerSetupDialogProps> = ({
    errors,
    loading = false,
    open = false,
    saving = false,
    webhookBaseUrl,
    zendeskUrl,
    webhookUrlId,
    workspaceLocked = false,
    onClose,
}) => {
    const appName = APP.ZENDESK.NAME;

    const canSave = !loading && !saving;

    const [webhookEvents, setToWebhookEvents] = useState<boolean>(true);

    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyUp={(event) => handleKeyDown({ event, enterCondition: canSave, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for {appName}
                </DialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        {workspaceLocked && (
                            <Alert
                                severity="warning"
                                alertTitle="Warning"
                                text="The workspace is currently locked and the token field cannot be edited"
                                sx={{ marginBottom: 2 }}
                            />
                        )}
                        {errors && <Alert severity="error" alertTitle="Error" text={errors} sx={{ marginBottom: 2 }} />}
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            <DialogContentText variant="subtitle2">
                                You need to setup an outgoing webhook in your {appName} project in order to listen to
                                events in ScriptRunner Connect.
                            </DialogContentText>
                            <DialogContentText component="ol">
                                {zendeskUrl && (
                                    <li>
                                        Visit your {appName}{' '}
                                        <Link target="_blank" href={zendeskUrl}>
                                            account
                                        </Link>{' '}
                                        page.
                                    </li>
                                )}
                                {!zendeskUrl && <li>Open https://[YOUR_ZENDESK.COM_ACCOUNT] URL in your browser.</li>}
                                <li>
                                    In <strong>Admin Centre</strong>, click <strong>Apps and integrations</strong> in
                                    the sidebar, then select <strong>Webhooks</strong>.
                                </li>
                                <li>
                                    Click the <strong>Create webhook</strong> button on the top right of the page.
                                </li>
                                <li>Select a way to connect:</li>
                                <Button
                                    onClick={() => setToWebhookEvents(true)}
                                    variant={webhookEvents ? 'outlined' : 'text'}
                                >
                                    Zendesk Events
                                </Button>
                                <Button
                                    onClick={() => setToWebhookEvents(false)}
                                    variant={!webhookEvents ? 'outlined' : 'text'}
                                >
                                    Trigger or Automation
                                </Button>
                                {webhookEvents && (
                                    <>
                                        <li>
                                            Subscribe to one or more Zendesk webhook events from the dropdown options.
                                        </li>
                                        <li>
                                            Click the <strong>Next</strong> button on the bottom right of the page.
                                        </li>
                                        <li>Enter a Name for the webhook.</li>
                                        <li>
                                            Paste the URL from this page into the <strong>Endpoint URL</strong> field.
                                        </li>
                                        <li>
                                            Click on <strong>Create webhook</strong>.
                                        </li>
                                    </>
                                )}
                                {!webhookEvents && (
                                    <>
                                        <li>
                                            Click the <strong>Next</strong> button on the bottom right of the page.
                                        </li>
                                        <li>Enter a Name for the webhook.</li>
                                        <li>
                                            Paste the URL from this page into the <strong>Endpoint URL</strong> field.
                                        </li>
                                        <li>
                                            Click on <strong>Create webhook</strong>.
                                        </li>
                                        <li>
                                            Click on{' '}
                                            {zendeskUrl ? (
                                                <Link
                                                    target="_blank"
                                                    href={`${zendeskUrl}/admin/objects-rules/rules/triggers`}
                                                >
                                                    Select a trigger or automation in Admin Centre
                                                </Link>
                                            ) : (
                                                <strong>
                                                    Objects and Rules {`>`} Business Rules {`>`} Triggers
                                                </strong>
                                            )}
                                            .
                                        </li>
                                        <li>
                                            Click the <strong>Create Trigger</strong> button on the top right of the
                                            page or edit an existing trigger.
                                        </li>
                                        <li>
                                            Enter a <strong>name</strong> and <strong>category</strong> for the trigger.
                                        </li>
                                        <li>
                                            Under <strong>Conditions</strong> provide a condition that must be met for
                                            the trigger to run.
                                        </li>
                                        <li>
                                            Under <strong>Actions</strong> click the <strong>Add action</strong> button.
                                        </li>
                                        <li>
                                            From the dropdown select the <strong>Notify by</strong> option, then select{' '}
                                            <strong>Active webhook</strong>.
                                        </li>
                                        <li>
                                            From the second dropdown select the webhook created in the previous steps.
                                        </li>
                                        <li>Edit the JSON body with events you would like to send.</li>
                                        <li>
                                            Click on <strong>Create</strong>.
                                        </li>
                                    </>
                                )}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Done</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};
