import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { StatuspageIcon } from '../../../icons/StatuspageIcon';
import { OpenInNewLink } from '../../../common/OpenInNewLink';
import { Button } from '../../../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';

interface StatuspageAppConfigureDialogProps {
    errors?: string;
    onSave(apiKey: string): void;
    onCancel(): void;
    open: boolean;
    saving?: boolean;
}

export const StatuspageAppConfigureDialog: React.FC<StatuspageAppConfigureDialogProps> = ({
    errors,
    onCancel,
    onSave,
    open = false,
    saving,
}) => {
    const [apiToken, setApiToken] = useState('');

    const handleSave = (): void => {
        if (apiToken) {
            onSave(apiToken);
        }
    };

    const handleCancel = (): void => {
        setApiToken('');
        onCancel();
    };

    return (
        <Dialog open={open}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<StatuspageIcon fontSize="medium" />} />
            {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
            <DialogContent>
                <DialogAlert
                    alertTitle="Information"
                    text="You need to be a Statuspage account owner to access API keys."
                    severity="info"
                />
                <DialogContentText>Authentication in Statuspage is done via an API token.</DialogContentText>
                <DialogContentText component="ol" sx={{ paddingLeft: 2.5 }}>
                    <li>
                        Log in to{' '}
                        <OpenInNewLink url={'https://manage.statuspage.io/login'}>
                            your Statuspage account
                        </OpenInNewLink>{' '}
                        .
                    </li>
                    <li>Click on your avatar in the top right of your screen to access the user menu.</li>
                    <li>
                        Click <strong>API info</strong>.
                    </li>
                    <li>
                        If you already have an API key which you want to use with ScriptRunner Connect, you can paste it
                        into the <strong>API token</strong> field and skip the rest of the steps by clicking{' '}
                        <strong>Save</strong>.
                    </li>
                    <li>
                        Otherwise click <strong>Create key</strong>, type in a name for your token and click{' '}
                        <strong>Confirm</strong>.
                    </li>
                    <li>
                        Copy the key and paste it to the <strong>API token</strong> field.
                    </li>
                </DialogContentText>
                <TextField
                    variant="outlined"
                    label="API token"
                    onChange={(event) => setApiToken(event.target.value)}
                    value={apiToken}
                    type="password"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} variant="outlined">
                    Cancel
                </Button>
                <Button busy={saving} onClick={handleSave} disabled={!apiToken} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
