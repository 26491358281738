import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import { Button } from '../common/buttons/Button';
import {
    MondayDialogConnectionNameInput,
    MondayDialogSubtitle,
    MondayDialogTitleLarge,
    StyledMondayDialogActions,
    StyledMondayDialogContent,
    StyledMondayDialogContentText,
    StyledMondayDialogOrderedList,
    StyledMondayDialog,
} from './MondayDialogComponents';
import { handleKeyDown } from '../../utils/handleKeyDown';

interface MondayUserOnboardingDialogProps {
    name: string;
    open?: boolean;
    saving?: boolean;
    onAuthorize: (name: string) => void;
}

export const MondayUserOnboardingDialog: React.FC<MondayUserOnboardingDialogProps> = ({
    name,
    open = false,
    saving = false,
    onAuthorize,
}) => {
    const [currentName, setCurrentName] = useState(name);

    useEffect(() => {
        setCurrentName(name);
    }, [open]);

    const canAuthorize = !!currentName && !saving;

    return (
        <StyledMondayDialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({ event, enterCondition: canAuthorize, enterFn: () => onAuthorize(currentName) })
            }
        >
            <MondayDialogTitleLarge title="Welcome to Advanced Automations" sx={{ mb: 0 }} />
            <StyledMondayDialogContent>
                <MondayDialogSubtitle title="Powered by ScriptRunner Connect" />
                <Divider sx={{ mb: 2 }} />
                <StyledMondayDialogContentText sx={{ mb: 3 }}>
                    To set up the connector, follow the steps below:
                </StyledMondayDialogContentText>
                <StyledMondayDialogOrderedList>
                    <li>Name your monday.com connector</li>
                    <MondayDialogConnectionNameInput
                        mb={3}
                        connectionType="monday.com"
                        value={currentName}
                        onChangeInputValue={(value) => setCurrentName(value)}
                    />
                    <li>Authorize by clicking the button below</li>
                </StyledMondayDialogOrderedList>
            </StyledMondayDialogContent>
            <StyledMondayDialogActions className="left">
                <Button busy={saving} disabled={!canAuthorize} onClick={() => onAuthorize(currentName)}>
                    Authorize
                </Button>
            </StyledMondayDialogActions>
        </StyledMondayDialog>
    );
};
