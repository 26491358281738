import Divider from '@mui/material/Divider';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { Button } from '../common/buttons/Button';
import {
    MondayDialogBadgeContainer,
    MondayDialogTitleLarge,
    StyledMondayDialogContentBox,
    StyledMondayDialogContent,
    StyledMondayDialogContentText,
    StyledMondayDialog,
} from './MondayDialogComponents';
import { handleKeyDown } from '../../utils/handleKeyDown';
import { useTheme } from '@mui/material';

interface MondayUserFailureDialogProps {
    open?: boolean;
    onClose: () => void;
}

export const MondayUserFailureDialog: React.FC<MondayUserFailureDialogProps> = ({ open = false, onClose }) => {
    const theme = useTheme();

    return (
        <StyledMondayDialog
            className="centered"
            open={open}
            onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
        >
            <MondayDialogBadgeContainer color={theme.palette.error.main} icon={<WarningOutlinedIcon />} centered />
            <MondayDialogTitleLarge title="Something went wrong..." />
            <StyledMondayDialogContentText sx={{ mb: 3 }}>
                An error has occured while setting up your connector
            </StyledMondayDialogContentText>
            <StyledMondayDialogContent className="centered">
                <StyledMondayDialogContentBox className="centered" width={300}>
                    <StyledMondayDialogContentText
                        variant="body2"
                        fontWeight="bold"
                        sx={(theme) => ({ textTransform: 'uppercase', color: theme.palette.text.secondary })}
                    >
                        Alternative option
                    </StyledMondayDialogContentText>
                    <StyledMondayDialogContentText sx={{ mb: 2 }}>
                        Setup your connector manually
                    </StyledMondayDialogContentText>
                    <Divider sx={{ mb: 2 }} />
                    <StyledMondayDialogContentText sx={{ mb: 2 }}>
                        Proceed to the connectors screen and manually configure your connector
                    </StyledMondayDialogContentText>
                    <Button onClick={onClose}>Configure manually</Button>
                </StyledMondayDialogContentBox>
            </StyledMondayDialogContent>
        </StyledMondayDialog>
    );
};
