import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { BillingDetailsScreen } from '../../../components/organizations/billing/BillingDetailsScreen';
import { BillingDetailsCardPlanOverview } from '../../../components/organizations/billing/BillingDetailsCardPlanOverview';
import { BillingDetailsInvoicePlanOverview } from '../../../components/organizations/billing/BillingDetailsInvoicePlanOverview';
import {
    changeOrganizationBillingDetailsAction$,
    organizationBillingDetails$,
    organizationBillingDetailsUpdateCancelledAction$,
    organizationBillingDetailsUpdatedAction$,
    organizationBillingEntityPermissionError$,
    savingOrganizationBillingDetails$,
    selectedOrganizationUid$,
    selectedOrganizationPlan$,
    organizationBillingDetailsCustomerPortal$,
    organizationSwitchedAction$,
} from '../../../store/organization';
import { getBasePath } from '../../../utils/path';
import { loggedInUserDetails$ } from '../../../store/user';
import { mondayServiceManagementUrl, supportPortalUrl } from '../../../utils/documentation';
import { MONDAY_ORIGIN } from '../../../utils/constants';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const OrganizationBillingDetailsContainer: React.FC = () => {
    const plan = useObservableState(selectedOrganizationPlan$);
    const savingBillingDetails = useObservableState(savingOrganizationBillingDetails$);
    const selectedOrgUid = useObservableState(selectedOrganizationUid$);
    const billingDetailsPermissionError = useObservableState(organizationBillingEntityPermissionError$);
    const customerPortalUrl = useObservableState(organizationBillingDetailsCustomerPortal$);
    const { billingEntities, selectedBillingEntityUid } = useObservableState(organizationBillingDetails$) || {};
    const { userOrigin } = useObservableState(loggedInUserDetails$) || {};

    const navigate = useNavigate();

    useSubscription(organizationSwitchedAction$, (uid) => {
        navigate({ to: `${getBasePath()}team/${uid}/billing` });
    });

    useSubscription(organizationBillingDetailsUpdatedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}/team/${selectedOrgUid}/billing` }), 100);
    });

    useSubscription(organizationBillingDetailsUpdateCancelledAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${selectedOrgUid}/billing` }), 100);
    });

    const isMondayUser = userOrigin === MONDAY_ORIGIN;

    if (plan?.salablePlan) {
        if (plan.paymentType === 'CARD') {
            return (
                <BillingDetailsCardPlanOverview
                    supportPortalUrl={isMondayUser ? mondayServiceManagementUrl : supportPortalUrl}
                    onManage={customerPortalUrl ? () => window.open(customerPortalUrl, '_blank') : undefined}
                />
            );
        } else {
            const selectedBillingEntity = billingEntities?.find((entity) => entity.uid === selectedBillingEntityUid);
            return (
                <BillingDetailsInvoicePlanOverview
                    supportPortalUrl={isMondayUser ? mondayServiceManagementUrl : supportPortalUrl}
                    plan={plan}
                    billingEntity={selectedBillingEntity}
                />
            );
        }
    }

    return (
        <BillingDetailsScreen
            billingEntities={billingEntities ?? []}
            plan={plan}
            selectedBillingEntity={selectedBillingEntityUid ?? ''}
            saving={savingBillingDetails}
            error={billingDetailsPermissionError}
            onSave={(billingEntityUid: string) => {
                changeOrganizationBillingDetailsAction$.next({
                    organizationUid: selectedOrgUid ?? '',
                    billingEntityUid,
                });
            }}
            onEdit={(uid) => navigate({ to: `${getBasePath()}billing/${uid}` })}
            onCancel={() => organizationBillingDetailsUpdateCancelledAction$.next()}
        />
    );
};
