import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '../common/buttons/Button';
import {
    MondayDialogBadgeContainer,
    MondayDialogTitleLarge,
    StyledMondayDialogContentBox,
    StyledMondayDialogContent,
    StyledMondayDialogContentText,
    StyledMondayDialog,
} from './MondayDialogComponents';
import { MondayIcon } from '../icons/MondayIcon';
import { handleKeyDown } from '../../utils/handleKeyDown';
import { useTheme } from '@mui/material';

interface MondayUserSuccessDialogProps {
    open?: boolean;
    onViewTemplates: () => void;
}

export const MondayUserSuccessDialog: React.FC<MondayUserSuccessDialogProps> = ({ open = false, onViewTemplates }) => {
    const theme = useTheme();

    return (
        <StyledMondayDialog
            className="centered"
            open={open}
            onKeyDown={(event) => handleKeyDown({ event, enterFn: onViewTemplates, escFn: onViewTemplates })}
        >
            <MondayDialogBadgeContainer color={theme.palette.success.main} icon={<CheckIcon />} centered />
            <MondayDialogTitleLarge title="Congratulations!" />
            <StyledMondayDialogContentText sx={{ mb: 3 }}>
                Your connector is set up and ready to go
            </StyledMondayDialogContentText>
            <StyledMondayDialogContent className="centered">
                <StyledMondayDialogContentBox className="centered" width={300}>
                    <MondayIcon />
                    <StyledMondayDialogContentText sx={{ mb: 2 }}>
                        Discover monday.com templates
                    </StyledMondayDialogContentText>
                    <Divider sx={{ mb: 2 }} />
                    <StyledMondayDialogContentText sx={{ mb: 2 }}>
                        Find ready-made solutions or create your own templates for all your project needs
                    </StyledMondayDialogContentText>
                    <Button onClick={onViewTemplates}>Browse all Templates</Button>
                </StyledMondayDialogContentBox>
            </StyledMondayDialogContent>
        </StyledMondayDialog>
    );
};
