import { useObservableState, useSubscription } from 'observable-hooks';
import { TemplateSelector } from '../../components/templates/template-selector';
import {
    editTemplateAction$,
    viewTemplateAction$,
    navigateToWorkspaceFromTemplateEditAction$,
    navigateToReadOnlyTemplateFromViewTemplateAction$,
    filteredTemplates$,
    activeFilters$,
    updateIncomingFilterAction$,
    updateOutgoingFilterAction$,
    resetFilterAction$,
    quickFilters$,
    updateQuickFilterAction$,
    resetQuickFilterAction$,
    updatePackagesFilterAction$,
    packagesFilterItems$,
} from '../../store/templates';
import { loggedInUserDetails$ } from '../../store/user';
import { getBasePath } from '../../utils/path';
import { useNavigate } from 'react-location';
import { Templates } from '../../data/templates';
import { apps$ } from '../../store/apps';
import {
    newDuplicatedWorkspace$,
    newWorkspaceFromTemplate$,
    openNewBlankWorkspaceAction$,
} from '../../store/workspaces';
import { APP } from '@avst-stitch/repository-lib/constants';
import { MONDAY_ORIGIN } from '../../utils/constants';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';

export const TemplatesContainer: React.FC = () => {
    const templates = useObservableState(filteredTemplates$);
    const apps = useObservableState(apps$);
    const navigate = useNavigate();
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const userCanWorkWithTemplates = !!(loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates);

    const packagesFilterItems = useObservableState(packagesFilterItems$);
    const quickFilters = useObservableState(quickFilters$);
    const activeFilters = useObservableState(activeFilters$);

    useSubscription(navigateToWorkspaceFromTemplateEditAction$, (template) =>
        navigate({ to: `${getBasePath()}workspace/${template.workspaceUid}/environment/${template.environmentUid}` })
    );

    useSubscription(navigateToReadOnlyTemplateFromViewTemplateAction$, (templateUid) =>
        navigate({ to: `${getBasePath()}template/${templateUid}` })
    );

    const sortByName = (templates: Templates): Templates => {
        return [...templates].sort((prev, next) => prev.name.localeCompare(next.name));
    };

    const orderedTemplates = [
        ...sortByName(templates.filter((t) => t.isNew)),
        ...sortByName(templates.filter((t) => !t.isNew)),
    ];

    const handleIncomingFilterUpdate = (app: string): void => {
        updateIncomingFilterAction$.next(app);
    };
    const handleOutgoingFilterUpdate = (app: string): void => {
        updateOutgoingFilterAction$.next(app);
    };
    const handlePackagesFilterUpdate = (packageName: string): void => {
        updatePackagesFilterAction$.next(packageName);
    };
    const handleResetFilter = (): void => {
        resetFilterAction$.next();
    };

    const handleQuickFilterUpdate = (app: string): void => {
        updateQuickFilterAction$.next(app);
    };

    const handleQuickFilterReset = (): void => {
        resetQuickFilterAction$.next();
    };

    const isMondayUser = loggedInUserDetails?.userOrigin === MONDAY_ORIGIN;

    const isMondayUserTemplate = (incoming: string[], outgoing: string[]): boolean => {
        return incoming.every((app) => app === APP.MONDAY.NAME) && outgoing.every((app) => app === APP.MONDAY.NAME);
    };

    return (
        <TemplateSelector
            packagesFilterItems={!isMondayUser ? packagesFilterItems : []}
            activeFilters={!isMondayUser ? activeFilters : { count: 0, incoming: [], outgoing: [], packages: [] }}
            quickFilters={!isMondayUser ? quickFilters : { activeFiltersCount: 0, filters: [] }}
            onSelect={(templateUid) => {
                const template = templates.find((temp) => temp.uid === templateUid);
                if (template) {
                    viewTemplateAction$.next(templateUid);
                }
            }}
            isMondayUser={isMondayUser}
            templates={orderedTemplates
                .map(({ uid, name, draft, isNew, description, incomingApps, outgoingApps }) => ({
                    uid,
                    name,
                    description,
                    draft,
                    isNew,
                    incoming: incomingApps,
                    outgoing: outgoingApps,
                }))
                .filter((t) => (isMondayUser ? isMondayUserTemplate(t.incoming, t.outgoing) : true))}
            userCanWorkWithTemplates={userCanWorkWithTemplates}
            onEdit={(selectedTemplateUid) => {
                editTemplateAction$.next(selectedTemplateUid);
            }}
            onSubmitFeedback={() => {
                segmentAnalyticsTrack('Advanced Automations Feedback Submitted', {
                    userId: loggedInUserDetails?.uid,
                    stitchTeamMember: loggedInUserDetails?.stitchTeamMember,
                    userOrigin: loggedInUserDetails?.userOrigin,
                });
            }}
            appsForFilterOptions={apps}
            onCreateBlankWorkspace={() => {
                newWorkspaceFromTemplate$.next(undefined);
                newDuplicatedWorkspace$.next(undefined);
                openNewBlankWorkspaceAction$.next('blank');
            }}
            onResetFilter={handleResetFilter}
            onIncomingFilterUpdate={handleIncomingFilterUpdate}
            onOutgoingFilterUpdate={handleOutgoingFilterUpdate}
            onPackagesFilterUpdate={handlePackagesFilterUpdate}
            onQuickFilterReset={handleQuickFilterReset}
            onQuickFilterUpdate={handleQuickFilterUpdate}
        />
    );
};
